import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as ReconnectIcon } from '@icons/wolfkit-light/arrow-2-cw-light.svg';
import Button from '@shared/ui/buttons/Button';
const ReconnectButton = ({ portfolio, onAction, fullWidth = false, }) => {
    const { t } = useTranslation();
    const onReconnectClick = (event) => {
        onAction(event, 'reconnect', portfolio.id);
    };
    return (_jsx(Button, { variant: 'filled', size: 'medium', color: 'error', startIcon: ReconnectIcon, fullWidth: fullWidth, onClick: onReconnectClick, style: {
            minWidth: '120px',
        }, children: t('portfolio.reconnect') }));
};
export default ReconnectButton;
