import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { ContainerRow } from '@components/styled';
import { Body, Subtitle } from '@components/styled/Typography';
import { ReactComponent as InfoIcon } from '@icons/wolfkit-solid/info-circle-solid.svg';
import Icon from '@shared/ui/icons/Icon';
import ReconnectButton from '../ReconnectButton';
const Container = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    gap: '36px',
}));
const MessageContainer = styled(ContainerRow)((props) => ({
    height: 'auto',
    width: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.m,
}));
const ApiConnectionFailureInline = ({ portfolio, onAction, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsxs(Container, { children: [_jsxs(MessageContainer, { children: [_jsx(Icon, { size: 16, color: theme.palette.error.main, IconComponent: InfoIcon }), _jsx(Subtitle
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    , { 
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component: 'span', children: `${t('portfolio.api_connection_failure')}.` }), _jsx(Body
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    , { 
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component: 'span', children: t('portfolio.update_api_key') })] }), _jsx(ReconnectButton, { portfolio: portfolio, onAction: onAction })] }));
};
export default ApiConnectionFailureInline;
